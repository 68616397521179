const VapidKeys = {
    // 公钥
    publicKey: 'BNz2uhYeNgNuvkrZKL9kqSJL8M_Y7HJTshWzrmCHcnVA7xQzIgFsM1KvlGAppYfbiVeIu2a0ZQCuvYwR8brYo1E',
    // // 私钥
    // privateKey: 'bp8ZaqDLRWjDbNSwX6os6AdlvNcpEUu2NwWCAA6GRhQ',
};

function urlBase64ToUint8Array(base64String: string) {
    try {
        const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
        const base64 = (base64String + padding)
            // eslint-disable-next-line no-useless-escape
            .replace(/\-/g, '+')
            .replace(/_/g, '/');

        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);

        for (let i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    } catch {
        return undefined;
    }
}

/**
 * @zh TODO@zhongjingqing replaced by firebase
 */
export async function getPushPermissionState() {
    try {
        if (!navigator.serviceWorker || !window.PushManager) {
            return;
        }

        const register = await navigator.serviceWorker.getRegistration(`${window?.location?.origin}/firebase-messaging-sw.js`);
        const subscriptionPermission = await register?.pushManager?.permissionState?.({
            userVisibleOnly: true,
            applicationServerKey: urlBase64ToUint8Array(VapidKeys.publicKey),
        });

        return subscriptionPermission;
    } catch (err) {
        console.log('IsRegisterPushServiceWorker Error: ', err);
    }
}

/**
 * @zh TODO@zhongjingqing replaced by firebase
 */
export async function registerPushServiceWorker(
    onSuccess?: (pushSubscription?: PushSubscription) => Promise<void> | void,
    onFailed?: () => Promise<void> | void
) {
    if (!navigator.serviceWorker || !window.PushManager) {
        return;
    }

    try {
        const register = await navigator.serviceWorker.register(`${window?.location?.origin}/firebase-messaging-sw.js`);
        const subscription = await register.pushManager.getSubscription();

        if (!subscription) {
            const newSubscription = await register.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: urlBase64ToUint8Array(VapidKeys.publicKey),
            });
            console.log('User is subscribed:', JSON.stringify(newSubscription));
            await onSuccess?.();
        }
    } catch (err) {
        console.log('RegisterPushServiceWorker Error: ', err);
        await onFailed?.();
    }
}
