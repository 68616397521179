
import { defineComponent, ref, getCurrentInstance, onMounted, computed, onUnmounted, defineAsyncComponent, PropType } from 'vue';
import Topbar from './components/topbar/index.vue';
// import ActionBar from './components/action-bar/index.vue';
// import VideoInfo from './components/video-info/index.vue';
import FeedList from './components/feed-list/index.vue';
import { registerPushServiceWorker, getPushPermissionState } from './utils';
// import LoginPopup from '@/components/login/index.vue';
// import LogoutPopup from '@/components/logout-popup/index.vue';
import { Feed } from '@/api/feed';
import device from '@/common/device-info';
import { sendLog } from '@/common/radar';
import { VideoRef } from './type';
import { FeedPageEnum } from '@/store/modules/feed';
/**
 * @zh 用于上报 通知权限、下载的状态
 */
enum OpenStatus {
    Close = 0,
    Opend = 1,
    Opening = 2,
}

const PwaShareNewContainerId = 'pwa-share-new-container';

const IconMap = {
    InstallKwaiIcon: 'https://aws-static.ap4r.com/kos/s101/nlav11312/kos/s101/nlav11312/pwa/mobile/install-kwai-icon.png',
    PushIcon: 'https://cdn-static.ap4r.com/kos/s101/nlav11312/kos/s101/nlav11312/pwa/mobile/push-icon.png',
    InstallSnackIcon: 'https://aws-static.ap4r.com/kos/s101/nlav11312/kos/s101/nlav11312/pwa/mobile/install-snack-icon.png',
    SharePopupTopIcon: 'https://aws-static.ap4r.com/kos/s101/nlav11312/kos/s101/nlav11312/pwa/mobile/share-popup-top-icon.png',
    KwaiIcon: 'https://cdn-static.ap4r.com/kos/s101/nlav11312/kos/s101/nlav11312/pwa/mobile/kwai-icon.png',
    SnackIcon: 'https://cdn-static.ap4r.com/kos/s101/nlav11312/kos/s101/nlav11312/pwa/mobile/snack-icon.png',
};

const PwaVideoDetailShare = defineComponent({
    components: {
        FeedList,
        Topbar,
        // ActionBar: defineAsyncComponent(() => import(/* webpackChunkName: "pwa-share-new/action-bar" */ './components/action-bar/index.vue')),
        // VideoInfo: defineAsyncComponent(() => import(/* webpackChunkName: "pwa-share-new/video-info" */ './components/video-info/index.vue')),
        LoginPopup: defineAsyncComponent(() => import(/* webpackChunkName: "pwa-share-new/login-popup" */ '@/components/login/index.vue')),
        LogoutPopup: defineAsyncComponent(() => import(/* webpackChunkName: "pwa-share-new/logout-popup" */ '@/components/logout-popup/index.vue')),
        IncentivePendant: defineAsyncComponent(
            () => import(/* webpackChunkName: "pwa-share-new/incentive-pendant" */ './components/incentive-pendant/index.vue')
        ),
    },
    props: {
        feedPage: {
            type: String as PropType<FeedPageEnum> | undefined,
            default: FeedPageEnum.PwaShareNew,
        },
    },
    setup(props) {
        const proxy = getCurrentInstance()?.proxy;
        const loginPopupVisible = ref(false);
        const logoutPopupVisible = ref(false);
        const canShowSwipeGuider = ref(false);
        const currentFeed = ref<Feed>();
        const currentIndex = ref<number>(0);
        const currentVideoRef = ref<VideoRef | null | undefined>(null);
        // const showSliderGuide = ref(false);
        const gonnaSlideToAd = ref(false);
        const isShowIncentivePendant = ref(true);

        const appName = computed(() => {
            const name = (getCurrentInstance()?.proxy as { $appName?: string })?.$appName;
            return name || '';
        });

        const isLogin = computed(() => proxy?.$store?.state?.isLogin);

        const incentiveEntryImage = computed(() => {
            return proxy?.$store?.state?.abTestModule?.abTestParams?.web_fission_entry_2;
        });

        const showIncentivePendant = computed(() => {
            return !!incentiveEntryImage.value && proxy?.$store?.state?.startup?.bucket === 'br' && isShowIncentivePendant.value;
        });

        const handleCloseIncentivePendant = () => {
            isShowIncentivePendant.value = false;
        };

        const handleLoginPopupVisibilityChange = (visible: boolean) => {
            loginPopupVisible.value = visible;
        };

        const handleLogoutPopupVisibilityChange = (visible: boolean) => {
            logoutPopupVisible.value = visible;
        };

        const handleLogin = (loginStatus: boolean) => {
            if (loginStatus) {
                proxy?.$store?.commit?.('setIsLogin', true);
                handleLoginPopupVisibilityChange(false);
            }
        };

        const handleLogout = () => {
            proxy?.$store?.commit?.('setIsLogin', false);
            handleLogoutPopupVisibilityChange(false);
        };

        const handleLogoutCancel = () => {
            handleLogoutPopupVisibilityChange(false);
        };

        const handleClickTopbarMore = () => {
            if (isLogin.value) {
                handleLogoutPopupVisibilityChange(true);
                return;
            }
            handleLoginPopupVisibilityChange(true);
        };

        const handleFeedChange = (params?: { feed?: Feed; videoRef?: VideoRef; newIndex?: number }) => {
            const { feed, videoRef, newIndex } = params || {};
            currentFeed.value = feed;
            currentVideoRef.value = videoRef;
            currentIndex.value = newIndex || 0;
        };
        const handleSlideFeedTo = (params?: { feed?: Feed; toIndex?: number }) => {
            const { feed, toIndex } = params || {};
            if (feed?.lumina_ad) {
                gonnaSlideToAd.value = true;
            } else {
                gonnaSlideToAd.value = false;
            }
        };

        const handleHideSwipeGuide = (hide: boolean) => {
            canShowSwipeGuider.value = !hide;
        };

        const sendOpenPush = async () => {
            const permissionState = await getPushPermissionState();

            if (!permissionState) {
                return;
            }

            sendLog({
                type: 'click',
                name: 'REMINDER_RIGHT',
                value: {
                    type: 'BACKGROUND_TASK_EVENT',
                    is_open: permissionState === 'granted' ? OpenStatus.Opend : OpenStatus.Close,
                },
            });
        };

        const handleInstallPromptCallback = (event: Event) => {
            proxy?.$store?.commit?.('setDeferredPromptInstalled', true);
            const { isIOS } = device() || {};
            if (!event || isIOS) {
                return;
            }

            event.preventDefault();
            // eslint-disable-next-line no-undef
            window.deferredPrompt = event as BeforeInstallPromptEvent;
        };

        const handleGlobalClick = () => {
            try {
                proxy?.$store?.commit?.('feedMoudle/setMuted', false);

                if (proxy?.$store?.state?.abTestModule?.abTestParams?.is_full_screen && !proxy?.$isAppEnvironment) {
                    document.body?.requestFullscreen?.();
                    (document.body as any)?.webkitRequestFullscreen?.();
                }

                document.removeEventListener('click', handleGlobalClick);
            } catch (e) {
                document.removeEventListener('click', handleGlobalClick);
            }
        };

        onMounted(async () => {
            sendOpenPush();

            if (proxy?.$isAppEnvironment) {
                proxy?.$store?.commit?.('feedMoudle/setMuted', false);
            }

            window.addEventListener('beforeinstallprompt', handleInstallPromptCallback);
            document.addEventListener('click', handleGlobalClick);
        });

        onUnmounted(() => {
            window.removeEventListener('beforeinstallprompt', handleInstallPromptCallback);
            document.removeEventListener('click', handleGlobalClick);
        });

        return {
            PwaShareNewContainerId,
            appName,
            loginPopupVisible,
            logoutPopupVisible,
            currentFeed,
            currentIndex,
            currentVideoRef,
            gonnaSlideToAd,
            IconMap,
            feedPageEnum: props.feedPage || FeedPageEnum.PwaShareNew,
            canShowSwipeGuider,
            searchText: proxy?.$route?.params?.searchWord || '',
            isLogin,
            incentiveEntryImage,
            showIncentivePendant,

            handleClickTopbarMore,
            handleLogoutCancel,
            handleLogout,
            handleLogin,
            handleFeedChange,
            handleSlideFeedTo,
            handleLoginPopupVisibilityChange,
            handleHideSwipeGuide,
            handleCloseIncentivePendant,
        };
    },
});

export default PwaVideoDetailShare;
