
import { defineComponent, ref, SetupContext, getCurrentInstance, onMounted, PropType } from 'vue';
import Search from 'vant/lib/search';
import { sendLog } from '@/common/radar';
import { replaceRoutePhotoId } from '@/common/utils';

import { FeedPageEnum } from '@/store/modules/feed';

const ProfileIconCdnUrl = 'https://aws-static.ap4r.com/kos/s101/nlav11312/kos/s101/nlav11312/pwa/mobile/profile.png';
const SearchIconCdnUrl = 'https://aws-static.ap4r.com/kos/s101/nlav11312/kos/s101/nlav11312/pwa/mobile/search.png';

const TopBar = defineComponent({
    components: {
        Search,
    },
    props: {
        feedPage: {
            type: String as PropType<FeedPageEnum> | undefined,
            default: undefined,
        },
    },
    setup(props, ctx: SetupContext<Array<'onSearch' | 'onClickMore'>>) {
        const logoutPopupVisible = ref(false);
        const searchText = ref('');

        const { proxy } = getCurrentInstance() || {};

        const handleSearch = () => {
            // eslint-disable-next-line vue/require-explicit-emits
            ctx.emit('onSearch');
            let path = '/discover';

            if (props.feedPage === FeedPageEnum.DiscoverSearch && searchText.value) {
                path += `/${searchText.value}`;
            }

            sendLog({
                type: 'click',
                name: 'SEARCH_INPUT',
                value: {
                    keywords: searchText.value || '',
                },
            });
            window.open(path, '_blank');
        };

        const handleClickMore = () => {
            // eslint-disable-next-line vue/require-explicit-emits
            ctx.emit('onClickMore');
        };

        onMounted(() => {
            if (props.feedPage === FeedPageEnum.DiscoverSearch) {
                let encodeSearchWord = proxy?.$route?.params?.pathMatch;
                if (!encodeSearchWord) {
                    const url = window.location.href;
                    const match = url.match(/\/search\/(.*?)\/video/);
                    encodeSearchWord = match?.[1];
                }
                const decodeSearchWord = decodeURIComponent(encodeSearchWord || '');
                const searchWord = decodeSearchWord.replace(/-/g, ' ');
                searchText.value = searchWord;
            }
        });

        return {
            logoutPopupVisible,
            iconSrc: {
                ProfileIcon: ProfileIconCdnUrl,
                SearchIcon: SearchIconCdnUrl,
            },
            handleSearch,
            handleClickMore,
            FeedPageEnum,
            searchText,
        };
    },
});

export default TopBar;
