
import { defineComponent, ref, onMounted, getCurrentInstance } from 'vue';
import FeedList from './feed-list.vue';
import IOSFeedList from './ios-feed-list.vue';

import device from '@/common/device-info';

export default defineComponent({
    setup() {
        const { proxy }: any = getCurrentInstance() || {};

        const compRef = ref<typeof FeedList | typeof IOSFeedList>(FeedList);
        onMounted(() => {
            const { isNewIOSFeedStrategy } = proxy?.$store?.state?.abTestModule?.abTestParams;
            const { isIOS } = device();

            if (isNewIOSFeedStrategy && isIOS) {
                compRef.value = IOSFeedList;
            }
        });

        return {
            compRef,
        };
    },
});
